<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :scrollable="true"
            :resizable="true">
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import storeServices from '../Script/StoreServices.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'StoreGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                    page: 1,
                    pageSize: kendo_grid.default_grid_pagesize,
                    serverPaging: false,
                    serverFiltering: false,
                    serverSorting: false,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {
                                return { query: storeServices.readStoreQuery(),
                                         variables:  {type:"Store"}};
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {
                        data: function (response) {
                            if(response.data.GetContact == null){
                                return [];
                            }else{
                                return response.data.GetContact;
                            }
                        },
                        total: function (response) {
                            if(response.data.GetContact == null){
                                return 0;
                            }else{
                                return response.data.GetContact.length;
                            }
                        },
                    }
                }),
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: this.statusView }, 
                { field: "contact_code", title: "Kode", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "contact_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "metadata.profile.province", title: "Provinsi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "metadata.profile.city", title: "Kota", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "metadata.profile.address", title: "Alamat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "metadata.profile.phone1", title: "No. Telepon", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "metadata.profile.handphone1", title: "No. HP", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "metadata.profile.taxfree", title: "Bebas Pajak", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    template: "#= metadata.profile.taxfree==true?'Iya':'Tidak' #" }, 
                { field: "metadata.profile.grade", title: "Grade", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "metadata.profile.note", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.contact_id);
        })

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.contact_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.contact_id, true);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Store")
        },
        statusView(e){
            if(e.status == 'N'){
                return 'New'
            }else if(e.status == 'A'){
                return 'Active'
            }else if(e.status == 'I'){
                return 'Inactive'
            }else{
                return 'Error'
            }
        },
        changeStatus(status){
            this.dataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                type : "Store",
                                status : status
                            }
                            return { 
                                query: storeServices.readStoreQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetContact == null){
                            return [];
                        }else{
                            return response.data.GetContact;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetContact == null){
                            return 0;
                        }else{
                            return response.data.GetContact.length;
                        }
                    },
                }
            })
        }
    }
}
</script>